import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import izquierda from "../../assets/recipes/BOTON-IZQUIERDA.png";
import derecha from "../../assets/recipes/BOTON-DERECHA.png";
export const VideoView = ({ videoList }) => {
  const [iframeVisible, setIframeVisible] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = (event) => {
    event.preventDefault();
    setIframeVisible(false);

    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % videoList.length);
      setIframeVisible(true);
    }, 300);
  };
  const handlePrev = (event) => {
    event.preventDefault();
    setIframeVisible(false);

    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? videoList.length - 1 : prevIndex - 1
      );
      setIframeVisible(true);
    }, 300);
  };

  return (
    <div>
      <Row className="video__container align-items-center justify-responsive show__responsive">
        <Col xs={2}>
          {" "}
          <img
            className="video__left"
            src={izquierda}
            alt="boton izquierda"
            onClick={handlePrev}
          ></img>
        </Col>
        <Col xs={8}>
          <Row className="video__container align-items-center justify-responsive">
            <Col xs={10} lg={3}>
              <iframe
                className={`iframe ${iframeVisible ? "" : "fade-out"}`}
                width="100%"
                height="200"
                src={`https://www.youtube.com/embed/${
                  videoList[currentIndex % videoList.length].url
                }`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </Col>
            <Col xs={11} lg={4} className="marginTop__Responsive">
              <iframe
                className={`iframe ${iframeVisible ? "" : "fade-out"}`}
                width="100%"
                height="300"
                src={`https://www.youtube.com/embed/${
                  videoList[(currentIndex + 1) % videoList.length].url
                }`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </Col>
            <Col xs={10} lg={3} className="marginTop__Responsive">
              <iframe
                className={`iframe ${iframeVisible ? "" : "fade-out"}`}
                width="100%"
                height="200"
                src={`https://www.youtube.com/embed/${
                  videoList[(currentIndex + 2) % videoList.length].url
                }`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={2}>
          <img
            className="video__right"
            src={derecha}
            alt="boton derecha"
            onClick={handleNext}
          ></img>
        </Col>
      </Row>
      <Row className="video__container align-items-center justify-responsive hide__responsive">
        <Col xs={12} md={1}>
          <img
            className="video__left"
            src={izquierda}
            alt="boton izquierda"
            onClick={handlePrev}
          ></img>
        </Col>
        <Col xs={10} lg={3}>
          <iframe
            className={`iframe ${iframeVisible ? "" : "fade-out"}`}
            width="100%"
            height="200"
            src={`https://www.youtube.com/embed/${
              videoList[currentIndex % videoList.length].url
            }`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Col>
        <Col xs={11} lg={4} className="marginTop__Responsive">
          <iframe
            className={`iframe ${iframeVisible ? "" : "fade-out"}`}
            width="100%"
            height="300"
            src={`https://www.youtube.com/embed/${
              videoList[(currentIndex + 1) % videoList.length].url
            }`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Col>
        <Col xs={10} lg={3} className="marginTop__Responsive">
          <iframe
            className={`iframe ${iframeVisible ? "" : "fade-out"}`}
            width="100%"
            height="200"
            src={`https://www.youtube.com/embed/${
              videoList[(currentIndex + 2) % videoList.length].url
            }`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Col>
        <Col xs={1} className="hide__responsive">
          <img
            className="video__right"
            src={derecha}
            alt="boton derecha"
            onClick={handleNext}
          ></img>
        </Col>
      </Row>
    </div>
  );
};
